import {useEffect, useRef, useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {StepperComponent} from '../../../../_metronic/assets/ts/components'
import {Formik, Form, FormikValues} from 'formik'
import {createParcelSchemas, inits} from './CreateParcelWizardHelper'
import {DeliveryAddress} from './steps/DeliveryAddress'
import {ParcelDetails} from './steps/ParcelDetails'
import {createParcel} from '../core/_requests'
import {Parcel} from '../core/_models'
import {useIntl} from 'react-intl'
import {AlertModal} from '../../../../_metronic/layout/components/others/AlertModal'


import {getRelayPointById, getRelayPoints, getRelayPointsAgent} from '../../apps/RelayPoints/core/_requests'
import {RelayPoint} from '../../apps/RelayPoints/core/_models'
import {PrintModal} from '../../../../_metronic/layout/components/others/PrintParcelModal'
import { PickUpAddressAgent } from './steps/PickUpAddressAgent'
import { useAuth } from '../../auth'
import { useListView } from '../core/ParcelListViewProvider'

const HorizontalAddAgent = () => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [addresses, setAdresses] = useState<RelayPoint[]>([])
  const [relayPoints, setRelayPoints] = useState<RelayPoint[]>([])
  const [currentSchema, setCurrentSchema] = useState(createParcelSchemas[0])
  const [initValues] = useState<Parcel>(inits)
  const [isSubmitButton, setSubmitButton] = useState(false)
  const [showPrintModal, setShowPrintModal] = useState(false)
  const [currentParcel, setCurrentParcel] = useState<Parcel>(initValues)
  const {setMessageA, setShowA, messageA, showA} = useListView()
  const {currentUser} = useAuth()
//currentUser?.rp_id!
useEffect(() => {
  {/*  currentUser?.type === 'agent' && getRelayPointById(currentUser.rp_id)*/}
  getRelayPointsAgent().then((res) => {
   const existingAddress = res[0];

   const newAddress: RelayPoint = { ...existingAddress, label: 'Client - '+existingAddress.label };
   currentUser?.roles.length!>0?setAdresses(res):setAdresses([...res, newAddress]);
 })
 getRelayPoints().then((res) => {
   setRelayPoints(res)
 })
 }, [])

  const intl = useIntl()

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const prevStep = () => {
    if (!stepper.current) {
      return
    }
    stepper.current.goPrev()
    setCurrentSchema(createParcelSchemas[stepper.current.currentStepIndex - 1])
  }

  const step = () => {
    if (!stepper.current) {
      return
    }
    setCurrentSchema(createParcelSchemas[stepper.current.currentStepIndex])
    if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
      stepper.current.goNext()
    }
  }

  useEffect(() => {
    setSubmitButton(stepper.current?.currentStepIndex === stepper.current?.totatStepsNumber)
  }, [stepper.current?.currentStepIndex])
  const [isClicked, setIsClicked] = useState(false);

  const submitStep = (values: Parcel, actions: FormikValues) => {
    setIsClicked(true)
  
    createParcel(values)
      .then((res) => {
        if(res?.message){
          setMessageA('Colis déjà existant(e)!')
          setShowPrintModal(false)
        }
        else{
        setCurrentParcel(res as Parcel)
        setShowPrintModal(true)
        setMessageA(intl.formatMessage({id: 'MESSAGE.PARCEL_CREATED'}))}
        
      })
      .then(() => {
        setShowA(true)
      })
      .then(() => {
        setIsClicked(false)
        // actions.resetForm()
        stepper.current?.goto(1)
        setCurrentSchema(createParcelSchemas[0])
      })
      .catch((err: any) => {
        setMessageA(err.response.data.message)
        setIsClicked(false)
        setShowA(true)
      })
  }

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }
    loadStepper()
  }, [stepperRef])
  

  return (
    <>
      <AlertModal message={messageA} setMessage={setMessageA} show={showA} setShow={setShowA} />
      <div
        ref={stepperRef}
        className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
        id='kt_create_account_stepper'
      >
        {/* begin::Aside*/}
        <div className='card d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px me-9'>
          {/* begin::Wrapper*/}
          <div className='card-body px-6 px-lg-10 px-xxl-15 py-20'>
            {/* begin::Nav*/}
            <div className='stepper-nav'>
              {/* begin::Step 1*/}
              <div className='stepper-item current' data-kt-stepper-element='nav'>
                {/* begin::Wrapper*/}
                <div className='stepper-wrapper'>
                  {/* begin::Icon*/}
                  <div className='stepper-icon w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number'>1</span>
                  </div>
                  {/* end::Icon*/}

                  {/* begin::Label*/}
                  <div className='stepper-label'>
                    <h3 className='stepper-title'>{intl.formatMessage({id: 'TITLE.PARCEL'})}</h3>
                    <div className='stepper-desc fw-semibold'>
                      {intl.formatMessage({id: 'PARCEL.DETAILS'})}
                    </div>
                  </div>
                  {/* end::Label*/}
                </div>
                {/* end::Wrapper*/}

                {/* begin::Line*/}
                <div className='stepper-line h-40px'></div>
                {/* end::Line*/}
              </div>
              {/* end::Step 1*/}

              {/* begin::Step 2*/}
              <div className='stepper-item' data-kt-stepper-element='nav'>
                {/* begin::Wrapper*/}
                <div className='stepper-wrapper'>
                  {/* begin::Icon*/}
                  <div className='stepper-icon w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number'>2</span>
                  </div>
                  {/* end::Icon*/}

                  {/* begin::Label*/}
                  <div className='stepper-label'>
                    <h3 className='stepper-title'>
                      {intl.formatMessage({id: 'PARCEL.PICK_UP_ADDRESS'})}
                    </h3>
                    <div className='stepper-desc fw-semibold'>
                      {intl.formatMessage({id: 'PARCEL.PICK_UP_ADDRESS_DETAILS'})}
                    </div>
                  </div>
                  {/* end::Label*/}
                </div>
                {/* end::Wrapper*/}

                {/* begin::Line*/}
                <div className='stepper-line h-40px'></div>
                {/* end::Line*/}
              </div>
              {/* end::Step 2*/}

              {/* begin::Step 3*/}
              <div className='stepper-item' data-kt-stepper-element='nav'>
                {/* begin::Wrapper*/}
                <div className='stepper-wrapper'>
                  {/* begin::Icon*/}
                  <div className='stepper-icon w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number'>3</span>
                  </div>
                  {/* end::Icon*/}

                  {/* begin::Label*/}
                  <div className='stepper-label'>
                    <h3 className='stepper-title'>
                      {intl.formatMessage({id: 'PARCEL.DELIVERY_ADDRESS'})}
                    </h3>
                    <div className='stepper-desc fw-semibold'>
                      {intl.formatMessage({id: 'PARCEL.DELIVERY_ADDRESS_DETAILS'})}
                    </div>
                  </div>
                  {/* end::Label*/}
                </div>
                {/* end::Wrapper*/}

                {/* begin::Line*/}
                {/* end::Line*/}
              </div>
              {/* end::Step 3*/}
            </div>
            {/* end::Nav*/}
          </div>
          {/* end::Wrapper*/}
        </div>
        {/* begin::Aside*/}
        {/* begin::Aside*/}
        <div className='d-flex flex-row-fluid flex-center bg-body rounded'>
          <Formik validationSchema={currentSchema} initialValues={initValues} onSubmit={submitStep}>
            {(props) => {
              const validateCurrentStep = () => {
                props.validateForm().then((errors) => {
                  if (Object.keys(errors).length === 0) {
                    step()
                  } else {
                    props.setErrors(errors)
                    props.setTouched({
                      quantity: true,
                      weight: true,
                      dimension: true,
                      pick_up_at: true,
                      updated_at: true,
                      created_at: true,
                   //   delivery_price: true,
                      sender: true,
                      receiver: true,
                    })
                  }
                })
              }
              const {touched, errors, values, setFieldValue, handleChange} = props
              return (
                <Form
                  className='mx-auto mw-600px w-100 pt-15 pb-10'
                  noValidate={false}
                  id='kt_create_account_form'
                >
                  <PrintModal
                    show={showPrintModal}
                    setShow={setShowPrintModal}
                    Parcel={currentParcel}
                  />
                  <div className='current' data-kt-stepper-element='content'>
                    <ParcelDetails
                      formik={{
                        errors: errors,
                        touched: touched,
                        values: values,
                        setFieldValue: setFieldValue,
                        handleChange: handleChange,
                      }}
                    />
                  </div>

                  <div data-kt-stepper-element='content'>
                    <PickUpAddressAgent
                      formik={{
                        errors: errors,
                        touched: touched,
                        setFieldValue: setFieldValue,
                        handleChange: handleChange,
                      }}
                       data={addresses}
                    
                    />
                  </div>

                  <div data-kt-stepper-element='content'>
                    <DeliveryAddress
                      formik={{
                        errors: errors,
                        touched: touched,
                        setFieldValue: setFieldValue,
                        handleChange: handleChange,
                      }}
                      data={relayPoints}
                    />
                  </div>

                  <div className='d-flex flex-stack pt-10'>
                    <div className='mr-2'>
                      <button
                        onClick={prevStep}
                        type='button'
                        className='btn btn-lg btn-light-primary me-3'
                        data-kt-stepper-action='previous'
                      >
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr063.svg'
                          className='svg-icon-4 me-1'
                        />
                        {intl.formatMessage({id: 'GENERAL.BACK'})}
                      </button>
                    </div>
                    <div>
                    <button
                      disabled={isClicked} 
                        type={isSubmitButton ? 'submit' : 'button'}
                        onClick={isSubmitButton ? () => null : validateCurrentStep}
                        className='btn btn-lg btn-primary me-3'
                      >
                         {isClicked ? 'Veuillez patienter...' :
                        <span className='indicator-label'>
                          {isSubmitButton
                            ? intl.formatMessage({id: 'GENERAL.SUBMIT'})
                            : intl.formatMessage({id: 'GENERAL.NEXT'})}
                          <KTSVG
                            path='/media/icons/duotune/arrows/arr064.svg'
                            className='svg-icon-3 ms-2 me-0'
                          />
                        </span>}
                      </button>
                    </div>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </div>
      </div>
    </>
  )
}

export {HorizontalAddAgent}
