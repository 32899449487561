/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {Field} from 'formik'
import {ParcelPersonModel} from '../../core/_models'
import {useIntl} from 'react-intl'
import {GetTranslate} from '../../../../../_metronic/helpers'
import {RelayPoint} from '../../../apps/RelayPoints/core/_models'
import {getAddressBookById} from '../../../apps/adressBook/core/_requests'
import {getRelayPointById} from '../../../apps/RelayPoints/core/_requests'
import {AddressBook} from '../../../apps/adressBook/core/_models'
import { useAuth } from '../../../../modules/auth'
import Select from 'react-select'

type Props = {
  user?: ParcelPersonModel
  loading?: boolean
  formik?: any
  data?: AddressBook[]| RelayPoint[] 
  role? :string
  IsSelect?: boolean
}

const PickUpAddress: FC<Props> = ({user, formik, data,loading, IsSelect}) => {
  const [address, setAddress] = useState<AddressBook | RelayPoint | undefined>(undefined)
  const [disabled, setDisabled] = useState<boolean>(false)
  const { currentUser } = useAuth()
  console.log(currentUser)

  const handleAdressSearch = async (id: number) => {
    if (id) {
      if (currentUser?.type==='provider') {
        await getAddressBookById(id)
          .then((res) => {
            setAddress(res);
          })
      } else {
        await getRelayPointById(id).then((res) => {
          setAddress(res)
        })
      }
    }
  };



  useEffect(() => {  

    if (address?.id) {
      formik.setFieldValue('sender.firstname', address?.name)
      formik.setFieldValue('sender.lastname', address?.label)
      formik.setFieldValue('sender.postal_code', address?.postal_code)
      formik.setFieldValue('sender.city', address?.city)
      formik.setFieldValue('sender.supplement', address?.supplement)
      formik.setFieldValue('sender.adresse', address?.address)
      formik.setFieldValue('sender.governorate', address?.governorate)
      formik.setFieldValue('sender.phone_number', address?.phone_number)
      formik.setFieldValue('sender.email', address?.email)
      formik.setFieldValue('sender.mobile', address?.mobile)
      setDisabled(true)
    }
  }, [address])




  const intl = useIntl()
  return (
    <div className='w-100 card' id='pickUpAddress'>
      <div className='card-header'>
        <h3 className='card-title'>{intl.formatMessage({id: 'PARCEL.PICK_UP_ADDRESS'})}</h3>
        <div className='input-group w-50'>
          <Select
         className='form-select form-select-solid'
          aria-label='Default select example'
          styles={{
            control: (provided) => ({
              ...provided,
              border: 0,
              boxShadow: 'none',
              backgroundColor: 'transparent',
            }),
          }}
           onChange={(e) => {
         if (e && e.value !== undefined) {
           handleAdressSearch(+e.value);
           }
         }}
        isDisabled={loading}
         options={[
           { value: '', label: intl.formatMessage({ id: 'MOVEMENT.SELECT_ADDRESS' }) },
           ...(data ?? []).map((item) => ({ value: item.id, label: item.name })),
           ]}
         />
        </div>
      </div>
      <div className='card-body'>
        <div className='row mb-1'>
          <div className='col-lg-4'>
            <div className='fv-row mb-0'>
              <label className='d-inline overflow-hidden col-lg-3 col-form-label required fw-bold fs-6'>
                {intl.formatMessage({id: 'COLUMN.FIRST_NAME'})}
              </label>
              <Field
                type='text'
                className='form-control form-control-lg form-control-white'
                name='sender.firstname'
                onChange={formik.handleChange}
                defaultValue={user?.firstname}
                disabled={loading}
              />
              <div className='text-danger mt-2'>
                {formik?.touched?.sender?.firstname &&
                  formik.errors.sender?.firstname &&
                  GetTranslate(formik.errors.sender?.firstname)}
              </div>
            </div>
          </div>
          <div className='col-lg-4'>
            <div className='fv-row mb-0'>
              <label className='d-inline overflow-hidden col-lg-6 col-form-label required fw-bold fs-6'>
                {intl.formatMessage({id: 'COLUMN.LAST_NAME'})}
              </label>
              <Field
                type='text'
                className='form-control form-control-lg form-control-white'
                name='sender.lastname'
                onChange={formik.handleChange}
                defaultValue={user?.lastname}
                 disabled={loading}
              />
              <div className='text-danger mt-2'>
                {formik?.touched?.sender?.lastname &&
                  formik.errors.sender?.lastname &&
                  GetTranslate(formik.errors.sender?.lastname)}
              </div>
            </div>
          </div>
          <div className='col-lg-4'>
            <div className='fv-row mb-0'>
              <label className='d-inline overflow-hidden col-lg-3 col-form-label required fw-bold fs-6'>
                {intl.formatMessage({id: 'USER.GOVERNORATE'})}
              </label>
              <Field
                type='text'
                className='form-control form-control-lg form-control-white'
                name='sender.governorate'
                onChange={formik.handleChange}
                defaultValue={user?.governorate}
                 disabled={loading}
              />
              <div className='text-danger mt-2'>
                {formik?.touched?.sender?.governorate &&
                  formik.errors.sender?.governorate &&
                  GetTranslate(formik.errors.sender?.governorate)}
              </div>
            </div>
          </div>
        </div>

        {/* Ligne 2 */}
        <div className='row mb-1'>
          <div className='col-lg-4'>
            <div className='fv-row mb-0'>
              <label className='d-inline overflow-hidden col-lg-10 col-form-label required fw-bold fs-6'>
                {intl.formatMessage({id: 'USER.POSTAL_CODE'})}
              </label>
              <Field
                type='text'
                className='form-control form-control-lg form-control-white'
                name='sender.postal_code'
                onChange={formik.handleChange}
                defaultValue={user?.postal_code}
                 disabled={loading}
              />
              <div className='text-danger mt-2'>
                {formik?.touched?.sender?.postal_code &&
                  formik.errors.sender?.postal_code &&
                  GetTranslate(formik.errors.sender?.postal_code)}
              </div>
            </div>
          </div>
          <div className='col-lg-4'>
            <div className='fv-row mb-0'>
              <label className='d-inline overflow-hidden  col-lg-6 col-form-label required fw-bold fs-6'>
                {intl.formatMessage({id: 'USER.CITY'})}
              </label>
              <Field
                type='text'
                className='form-control form-control-lg form-control-white'
                name='sender.city'
                onChange={formik.handleChange}
                defaultValue={user?.city}
                 disabled={loading}
              />
              <div className='text-danger mt-2'>
                {formik?.touched?.sender?.city &&
                  formik.errors.sender?.city &&
                  GetTranslate(formik.errors.sender?.city)}
              </div>
            </div>
          </div>
          <div className='col-lg-4'>
            <div className='fv-row mb-0'>
              <label className='required d-inline overflow-hidden col-lg-10 col-form-label  fw-bold fs-6'>
                {intl.formatMessage({id: 'COLUMN.MOBILEPHONE'})}
              </label>
              <Field
                type='text'
                className='form-control form-control-lg form-control-white'
                name='sender.mobile'
                onChange={formik.handleChange}
                defaultValue={user?.mobile}
                 disabled={loading}
              />
              <div className='text-danger mt-2'>
                {formik?.touched.sender?.mobile &&
                  formik?.errors.sender?.mobile &&
                  GetTranslate(formik.errors.sender?.mobile)}
              </div>
            </div>
          </div>
        </div>
        {/* third line  */}
        <div className='row mb-1'>
          <div className='col-lg-6'>
            <div className='fv-row mb-0'>
              <label className='d-inline overflow-hidden col-lg-3 col-form-label required fw-bold fs-6'>
                {intl.formatMessage({id: 'USER.ADDRESS'})}
              </label>
              <Field
                type='text'
                className='form-control form-control-lg form-control-white'
                name='sender.adresse'
                onChange={formik.handleChange}
                defaultValue={user?.adresse}
                 disabled={loading}
              />
              <div className='text-danger mt-2'>
                {formik?.touched?.sender?.adresse &&
                  formik.errors.sender?.adresse &&
                  GetTranslate(formik.errors.sender?.adresse)}
              </div>
            </div>
          </div>
          <div className='col-lg-6'>
            <label className='d-inline overflow-hidden  col-lg-6 col-form-label fw-bold fs-6'>
              {intl.formatMessage({id: 'COLUMN.HOMEPHONE'})}
            </label>
            <Field
              type='text'
              className='form-control form-control-lg form-control-white'
              name='sender.phone_number'
              onChange={formik.handleChange}
              defaultValue={user?.phone_number}
               disabled={loading}
            />
            <div className='text-danger mt-2'>
              {formik?.touched?.sender?.phone_number &&
                formik.errors.sender?.phone_number &&
                GetTranslate(formik.errors.sender?.phone_number)}
            </div>
          </div>
        </div>
        <div className='row mb-1'>
          <div className='col-lg-6'>
            <label className='d-inline overflow-hidden  col-lg-10 col-form-label fw-bold fs-6'>
              {intl.formatMessage({id: 'COLUMN.EMAIL'})}
            </label>
            <Field
              type='text'
              className='form-control form-control-lg form-control-white'
              name='sender.email'
              onChange={formik.handleChange}
              defaultValue={user?.email}
               disabled={loading}
            />
            <div className='text-danger mt-2'>
              {formik?.touched?.sender?.email &&
                formik.errors.sender?.email &&
                GetTranslate(formik.errors.sender?.email)}
            </div>
          </div>
          <div className='col-lg-6'>
            <label className='d-inline overflow-hidden  col-lg-6 col-form-label fw-bold fs-6'>
              {intl.formatMessage({id: 'USER.SUPPLEMENT'})}
            </label>
            <Field
              type='text'
              className='form-control form-control-lg form-control-white'
              name='sender.supplement'
              onChange={formik.handleChange}
              defaultValue={user?.supplement}
               disabled={loading}
            />
            <div className='text-danger mt-2'>
              {formik?.touched?.sender?.supplement &&
                formik.errors.sender?.supplement &&
                GetTranslate(formik.errors.sender?.supplement)}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {PickUpAddress}
