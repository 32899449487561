import {FC, useEffect, useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {Formik, Form, FormikValues} from 'formik'
import {createParcelSchemaOneStep, inits} from './CreateParcelWizardHelper'
import {PickUpAddressAgent} from './steps/PickUpAddressAgent'
import {DeliveryAddress} from './steps/DeliveryAddress'
import {ParcelDetails} from './steps/ParcelDetails'
import {Parcel} from '../core/_models'
import {createParcel, updateParcel} from '../core/_requests'
import {useListView} from '../core/ParcelListViewProvider'
import {ParcelUseQueryResponse} from '../core/ParcelQueryResponseProvider'
import {useIntl} from 'react-intl'
import {AlertModal} from '../../../../_metronic/layout/components/others/AlertModal'
import {RelayPoint} from '../../apps/RelayPoints/core/_models'
import {getRelayPoints,getRelayPointsAgent} from '../../apps/RelayPoints/core/_requests'
import {PrintModal} from '../../../../_metronic/layout/components/others/PrintParcelModal'
import {useAuth} from '../../auth'


type Props = {
  parcel?: Parcel
  loadingParcel?: boolean
  role?: string


}

const VerticalAddAgent: FC<Props> = ({parcel, loadingParcel,role}) => {
  const {setItemIdForUpdate, setMessageA, setShowA, messageA, showA} = useListView()
  const {refetch} = ParcelUseQueryResponse()
  const [addresses, setAdresses] = useState<RelayPoint[]>([])
  const [relayPoints, setRelayPoints] = useState<RelayPoint[]>([])
  const [showPrintModal, setShowPrintModal] = useState(false)
  const [currentParcel, setCurrentParcel] = useState<Parcel>(inits)
  const [currentSchema] = useState(createParcelSchemaOneStep)
  const [selectedParcel] = useState<Parcel>(parcel || inits)
  const {currentUser} = useAuth()

  useEffect(() => {
   {/*  currentUser?.type === 'agent' && getRelayPointById(currentUser.rp_id)*/}
   getRelayPointsAgent().then((res) => {
    const existingAddress = res[0];

    const newAddress: RelayPoint = { ...existingAddress, label: 'Client - '+existingAddress.label };
    currentUser?.roles.length!>0?setAdresses(res):setAdresses([...res, newAddress]);
  })
  getRelayPoints().then((res) => {
    setRelayPoints(res)
  })
  }, [])
  
  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

     /** Button  */
 const [isClicked, setIsClicked] = useState(false);

  const handleSubmit = async (values: Parcel, actions: FormikValues) => {
    setIsClicked(true)
    if (selectedParcel.id) {
      console.log(values)
      await updateParcel(values)
        .then(() => {
          setMessageA(intl.formatMessage({id: 'PARCEL.UPDATED'}))
          setShowA(true)
          cancel(true)
        })
        .catch((err) => {
          setMessageA(err.response.data.message)
          setShowA(true)
          cancel(true)
        })
    } else {
      await createParcel(values)
        .then((res) => {
          if(res?.message){
            setShowA(true)
            setMessageA('Colis déjà existant(e)!')
            setShowPrintModal(false)
            cancel(true)
            setIsClicked(false)
            // actions.resetForm()
          }else{
          setCurrentParcel(res as Parcel)
          setShowPrintModal(true)
          setMessageA(intl.formatMessage({id: 'PARCEL.CREATED'}))
          setShowA(true)
          cancel(true)
          setIsClicked(false)
          // actions.resetForm()
          }
        })
        .catch((err) => {
          setMessageA(err.response.data.message)
          setIsClicked(false)
          setShowA(true)
          cancel(true)
        })
    }
  }

  const intl = useIntl()
  return (
    <>
      <AlertModal message={messageA} setMessage={setMessageA} show={showA} setShow={setShowA} />
      <Formik
        initialValues={selectedParcel}
        validationSchema={currentSchema}
        enableReinitialize={true}
        onSubmit={handleSubmit}
      >
        {(props) => {
          const {touched, errors, handleBlur, values, setFieldValue, handleChange} = props
          console.log(props)
          return (
            <Form className='w-100' noValidate={true} id='kt_create_account_form'>
              <div
                data-kt-stepper-element='content'
                className='d-flex flex-row-fluid flex-center bg-body rounded p-6 mb-4'
              >
                <PrintModal
                  show={showPrintModal}
                  setShow={setShowPrintModal}
                  Parcel={currentParcel}
                />
                <ParcelDetails
                  quantity={selectedParcel.quantity}
                  weight={selectedParcel.weight}
                  dimension={selectedParcel.dimension}
                  reference={selectedParcel.reference}
                  price={selectedParcel.price}
                  pick_up_at={selectedParcel.pick_up_at}
                  content={selectedParcel.content}
                  loading={loadingParcel}
                  formik={{
                    errors: errors,
                    touched: touched,
                    blur: handleBlur,
                    values: values,
                    setFieldValue: setFieldValue,
                    handleChange: handleChange,
                  }}
                />
              </div>
              <div
                data-kt-stepper-element='content'
                className='d-flex flex-row-fluid flex-center bg-body rounded p-6 mb-4'
              >
                 
                <PickUpAddressAgent
                  role={role}
                  user={selectedParcel.sender}
                  loading={loadingParcel}
                  formik={{
                    errors: errors,
                    touched: touched,
                    setFieldValue: setFieldValue,
                    handleChange: handleChange,
                  }}
                  data={addresses}
                /> 
                
              </div> 
              <div
                data-kt-stepper-element='content'
                className='d-flex flex-row-fluid flex-center bg-body rounded p-6 '
              >
                <DeliveryAddress
                  user={selectedParcel.receiver}
                  loading={loadingParcel}
                  formik={{
                    errors: errors,
                    touched: touched,
                    setFieldValue: setFieldValue,
                    handleChange: handleChange,
                  }}
                  
                   data={relayPoints}
                
                />
              </div>
              <div className='m-4 text-end'>
                <button disabled={isClicked}  type='submit' className='btn btn-primary'>
                {isClicked ? 'Veuillez patienter...' :
                <span>
                 {'Cliquez pour continuer'}
                <KTSVG
                 path='/media/icons/duotune/arrows/arr064.svg'
                  className='svg-icon-3 ms-2 me-0'
             />
            </span>
              } 
                </button>
              </div>
            </Form>
          )
        }}
      </Formik>
    </>
  )
}

export {VerticalAddAgent}
